import React from 'react'
import '../Static/main.css'
import Portrait from '../images/profilePic.jpeg'
import Linkedin from '../images/linkedin.png'
import Github from '../images/github.png'
import Transactional from '../images/Transactional.png'
import Newsletter from '../images/Newsletter.png'
import Promotional from '../images/shopmore.png'


const Main = () => {
    return (
        <div className="app">
            <div className="header">
                <div className="background" style={{backgroundImage: "url(nature.jpg)"}}>
                    <div className="nav">
                        <div className="name">
                            <p>Jadon Valdez</p>
                        </div>
                        <div className="links">
                            <div>
                                <a className='link' href="#projects">Projects</a>
                            </div>
                            <div>
                                <a  className='link' href="#contact">Contact</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info">
                    <div className="photo">
                        <img className="portrait" src={Portrait} alt="" />
                    </div>
                    <div className="bio">
                        <p>"I am an HTML Email Developer with experience in building
                            responsive and stylish emails and Email on Acid"</p>
                    </div>
                    <div className="socials">
                        <div>
                            <a href="https://www.linkedin.com/in/jadon-valdez-21b687222/" target="_blank"><img 
                            src={Linkedin} alt="linkedin" className='linkedin'/></a>
                        </div>
                        <div>
                            <a href="https://github.com/Jadon-Valdez" target="_blank" ><img 
                                src={Github} alt="github" className='github'/></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='projects'>
                <h1 className='projectOpening' id='projects'>Projects</h1>
                <p className='projectsInfo'>Here is a small showcase of some of my most recent projects to get a glimpse of
                    the work that I produce
                </p>
                <div className='allProjects'>
                    <div>
                        <a href="https://securishield.netlify.app/" target="blank"><img src={Transactional} 
                            alt="" className='onePhoto'/>
                        </a>
                        <p className='projectTitle'>Transactional</p>
                    </div>
                    <div>
                        <a href="https://shopmorerewards.netlify.app/" target="blank"><img src={Promotional} 
                            alt="" className='onePhoto'/>
                        </a>
                        <p className='projectTitle'>Promotional</p>
                    </div>
                    <div>
                        <a href="https://experian.netlify.app/" target="blank"><img src={Newsletter}
                            alt="" className='onePhoto'/>
                        </a>
                        <p className='projectTitle'>Newsletter</p>
                    </div>
                </div>
            </div>
            <div className='opportunities'>
                <div className='smallBox'>
                    <h1>Open to Opportunities</h1>
                    <p style={{fontSize: '16pt', marginTop: '20px'}}>If you like my work, please contact me so we 
                        can chat about opportunities that are available.  
                        I look forward to helping you solve your problems and taking
                        things to the next level.</p>
                </div>
            </div>
            <div className='contact'>
                <div className="smallBox2">
                    <h1 id='contact'>Contact Me</h1>
                    <p style={{marginTop: '20px'}}>valdezjadon@gmail.com</p>
                    <p>719.251.8899</p>
                </div>
            </div>
        </div>
    )
}

export default Main