import logo from './logo.svg';
import './App.css';
import Main from './Components/Main'
import {
  BrowserRouter,
  Link,
  Switch,
  Route
} from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Main />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
